/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.2.1/dist/jquery.min.js
 * - /npm/cloudinary_js@2.6.0/js/jquery.cloudinary.min.js
 * - /npm/bootstrap@4.3.0/dist/js/bootstrap.bundle.min.js
 * - /npm/axios@0.19.0/dist/axios.min.js
 * - /npm/vue@2.6.11/dist/vue.min.js
 * - /npm/vue-infinite-loading@2.4.5/dist/vue-infinite-loading.min.js
 * - /npm/balance-text@3.3.0/balancetext.min.js
 * - /npm/jarallax@2.0.2/dist/jarallax.min.js
 * - /npm/jarallax@2.0.2/dist/jarallax-video.min.js
 * - /npm/nanogallery2@3.0.5/dist/jquery.nanogallery2.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
